import * as tslib_1 from "tslib";
import { CompanyDetailPageComponent } from '@agent-ds/company/pages';
import { JobDetailPageComponent } from '@agent-ds/jobs/pages/job-detail-page/job-detail-page.component';
import { JobTabs } from '@agent-ds/jobs/pages/job-detail-page/tabs/job-tabs.enum';
import { JobStudentMatchingPageComponent } from '@agent-ds/matching/pages/job-student-matching-page/job-student-matching-page.component';
import { RAPACA_VALUE_BADGE_CLASSES } from '@agent-ds/shared/constants';
import { DashboardType, EnterpriseDepartmentUserType, JobSearchParams, } from '@agent-ds/shared/interfaces';
import { SafeDatePipe } from '@agent-ds/shared/pipes/safe-date.pipe';
import { AuthService, DialogService, JobApiService } from '@agent-ds/shared/services';
import { MasterApiService } from '@agent-ds/shared/services/api/master-api.service';
import { DecimalPipe, Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { JobCreateDialogComponent } from '../../../jobs/components/job-create-dialog/job-create-dialog.component';
import { PageScrollTableComponent } from '../page-scroll-table/page-scroll-table.component';
import { JOBS_TABLE_CONFIG } from './jobs-table-config';
var oneDay = 24 * 60 * 60 * 1000;
var JobRibbonType;
(function (JobRibbonType) {
    JobRibbonType[JobRibbonType["NEW"] = 1] = "NEW";
    JobRibbonType[JobRibbonType["\u518D\u30AA\u30FC\u30D7\u30F3"] = 2] = "\u518D\u30AA\u30FC\u30D7\u30F3";
})(JobRibbonType || (JobRibbonType = {}));
var JobRibbonClasses = {
    1: 'ribbon__label__red',
    2: 'ribbon__label__orange',
};
var RecommendationHeatClasses = {
    次月: 'badge--yellow-light',
    次月注力: 'badge--yellow-light',
    月内: 'badge--red-light-alt',
};
var JobListComponent = /** @class */ (function () {
    function JobListComponent(masterApiService, service, datePipe, decimalPipe, authService, cdr, dialog, location, router) {
        var _this = this;
        this.masterApiService = masterApiService;
        this.service = service;
        this.datePipe = datePipe;
        this.decimalPipe = decimalPipe;
        this.authService = authService;
        this.cdr = cdr;
        this.dialog = dialog;
        this.location = location;
        this.router = router;
        this.requestObject = {};
        this.options = {};
        this.itemChecked = new EventEmitter();
        this.classMap = {
            大手: 'badge--red',
            中堅以上: 'badge--green-light',
            中堅: 'badge--green-light',
            中小: 'badge--blue',
            販サOS介護: 'badge--orange-seg',
            IT注力: 'badge--purple-light2',
        };
        this.jobDepartmentUserType = EnterpriseDepartmentUserType;
        this.jobRibbonType = JobRibbonType;
        this.jobRibbonClasses = JobRibbonClasses;
        this.recommendationHeatClasses = RecommendationHeatClasses;
        this.RAPACA_VALUE_BADGE_CLASSES = RAPACA_VALUE_BADGE_CLASSES;
        this.jobTypes = [];
        this.industryTypes = [];
        this.filterOpen = false;
        this.initialized = false;
        this.loadJobs = function (page, size, sort, order) {
            var params = _this.cleanObject(tslib_1.__assign({}, _this.requestObject, { from: page * size, size: size, sort: sort === 'updatedAt' && _this.matchingMode ? 'matching' : sort, order: order }));
            if (_this.options && _this.options.filterOpen && !_this.filterOpen) {
                params.status = ['作成中', '企業確認待ち', '企業確認済み', '募集中', '推薦停止'];
            }
            if (!params.sort) {
                params.sort = _this.options.sort.field;
                params.order = _this.options.sort.order;
            }
            return _this.service.getList(params).pipe(map(function (res) { return _this.convertJobList(res); }));
        };
    }
    Object.defineProperty(JobListComponent.prototype, "checkedItems", {
        get: function () {
            return this.table ? this.table.checkedItems : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobListComponent.prototype, "allChecked", {
        get: function () {
            return this.table.allChecked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobListComponent.prototype, "totalSize", {
        get: function () {
            return this.table ? this.table.totalSize : 0;
        },
        enumerable: true,
        configurable: true
    });
    JobListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authService.isLogined().subscribe(function (logined) {
            // 副担当者かどうかを確認
            _this.isIntakeCa = _this.authService.loginUser && _this.authService.loginUser.dashboardType === DashboardType.INTAKE_CA;
        });
        this.refreshSubscription = this.service.refreshEvent.subscribe(function () { return _this.ngOnChanges(); });
        this.masterApiService
            .getJobTypes()
            .subscribe(function (types) { return (_this.jobTypes = types.flatten(function (subType, level) { return (subType['_selectLevel'] = level); }, 'jobTypes')); });
        this.masterApiService
            .getIndustryTypes()
            .subscribe(function (types) { return (_this.industryTypes = types.flatten(function (subType, level) { return (subType['_selectLevel'] = level); }, 'industryTypes')); });
        if (!this.options.sort) {
            this.options.sort = { field: 'updatedAt', order: 'desc' };
        }
        if (this.router.url.endsWith('/jobs/add')) {
            this.openJobCreateDialog('jobs/list');
        }
        this.jobLoader = this.content ? null : this.loadJobs;
    };
    JobListComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || (changes['requestObject'] && changes['requestObject'].previousValue !== changes['requestObject'].currentValue)) {
            this.cdr.detectChanges();
            if (this.table && this.initialized) {
                this.table.reset(true);
                this.table.next();
            }
        }
        if (changes && changes['content'] && changes['content'].previousValue !== changes['content'].currentValue) {
            this.jobLoader = changes['content'].currentValue ? null : this.loadJobs;
        }
        if (changes && changes['dynamicColumns'] && !!changes['dynamicColumns'].previousValue !== !!changes['dynamicColumns'].currentValue) {
            this.configureTable();
        }
    };
    JobListComponent.prototype.ngAfterViewInit = function () {
        this.configureTable();
        this.cdr.detectChanges();
        this.initialized = true;
        if (this.requestObject && this.table) {
            this.table.reset(true);
            this.table.next();
        }
    };
    JobListComponent.prototype.configureTable = function () {
        var _this = this;
        this.tableConfig = JOBS_TABLE_CONFIG(this.dynamicColumns, this.dynamicRows, this.newArrivalTemplate, this.personsInChargeTemplate, this.companyTemplate, this.positionTemplate, this.heatTemplate, this.favoritesTemplate, this.customActionsTemplate || this.actionsTemplate, function (data) { return _this.datePipe.transform(data, 'yyyy/MM/dd'); }, function (num) { return _this.decimalPipe.transform(num, '0.0-1'); }, function (data) { return (data ? (_this.jobTypes.find(function (type) { return type.code === data.code1; }) || { name: '' }).name : ''); }, function (data) { return (_this.industryTypes.find(function (industry) { return industry.code === data; }) || { name: '' }).name; }, this.options);
    };
    JobListComponent.prototype.ngOnDestroy = function () {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    };
    JobListComponent.prototype.convertJobList = function (res) {
        var _this = this;
        // default result
        var result = {
            result: [],
            totalSize: res && res.total ? res.total : 0,
        };
        // map all elements to make object root to _source property
        if (res && res.jobs && res.jobs.length > 0) {
            result.result = res.jobs;
        }
        result.result.forEach(function (job) {
            var postedDate = Date.parse(job.postedAt);
            if ((Date.now() - postedDate) / oneDay < 7 && job.dynamicData && job.dynamicData.status === '募集中') {
                job.ribbon = job.postingCount === 1 ? 1 : job.postingCount > 1 ? 2 : undefined;
            }
            if (job.jobUserFavorite) {
                var currentUserId_1 = _this.authService.loginUser ? _this.authService.loginUser.id : undefined;
                job.favorite = job.jobUserFavorite.find(function (userFavorite) { return userFavorite.userId === currentUserId_1; }) !== undefined;
            }
        });
        return result;
    };
    JobListComponent.prototype.cleanObject = function (obj) {
        for (var propName in obj) {
            if (obj[propName] == null || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj;
    };
    JobListComponent.prototype.filterOpenChanged = function () {
        this.filterOpen = !this.filterOpen;
        this.table.reset(true);
        this.table.next();
    };
    JobListComponent.prototype.onItemSelected = function (selectedItem) {
        JobDetailPageComponent.instance.referenceId = selectedItem.id;
        JobDetailPageComponent.instance.open();
    };
    JobListComponent.prototype.onItemChecked = function (checkedItemEvent) {
        this.itemChecked.emit(checkedItemEvent);
    };
    JobListComponent.prototype.onCompanyLinkClick = function (companyId) {
        CompanyDetailPageComponent.instance.referenceId = companyId;
        CompanyDetailPageComponent.instance.open();
    };
    JobListComponent.prototype.onJobLinkClick = function (job, tab) {
        if (tab === 'userList') {
            JobDetailPageComponent.instance.selectedSideActionIndex = 1;
        }
        else if (tab === 'matching') {
            JobStudentMatchingPageComponent.instance.referenceId = job.id;
            JobStudentMatchingPageComponent.instance.open();
            return;
        }
        else {
            JobDetailPageComponent.instance.tabCode = tab;
        }
        this.onItemSelected(job);
    };
    JobListComponent.prototype.onFavoriteClicked = function (job) {
        if (job.favorite) {
            this.service.deleteFavorite(job.id).subscribe(function () {
                job.favorite = false;
            });
        }
        else {
            this.service.addFavorite(job.id).subscribe(function () {
                job.favorite = true;
            });
        }
    };
    JobListComponent.prototype.openJobCreateDialog = function (origUrl) {
        var _this = this;
        if (origUrl === void 0) { origUrl = this.location.path(); }
        if (this.dialogRef) {
            return;
        }
        this.location.go('/jobs/add');
        JobDetailPageComponent.instance.close();
        this.dialogRef = this.dialog.open(JobCreateDialogComponent, { data: { enterpriseId: this.requestObject.enterpriseId } });
        var closeSubscription;
        var navigationSubscription;
        var unsubscribe = function () {
            if (closeSubscription) {
                closeSubscription.unsubscribe();
            }
            if (navigationSubscription) {
                navigationSubscription.unsubscribe();
            }
        };
        closeSubscription = this.dialogRef.afterClosed.subscribe(function (res) {
            _this.location.go(origUrl);
            _this.dialogRef = null;
            if (res && res.id) {
                JobDetailPageComponent.instance.tabCode = JobTabs[JobTabs.DETAIL];
                _this.onItemSelected(res);
            }
            unsubscribe();
        });
        navigationSubscription = this.location.subscribe(function () {
            _this.dialogRef.close();
            unsubscribe();
        });
    };
    return JobListComponent;
}());
export { JobListComponent };
